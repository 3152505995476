*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.block {
  display: block;
}

.outline {
  outline-style: solid;
}

@font-face {
  font-family: HVDPoster;
  font-style: normal;
  font-format: truetype;
  font-weight: 400;
  src: url("hvd poster.5577288c.ttf");
}

:root {
  --dl-color-gray-700: #999;
  --dl-color-gray-900: #c3c5c9;
  --dl-size-size-unit: 1rem;
  --dl-color-gray-black: #000;
  --dl-color-gray-white: #fff;
  --dl-color-pimary-300: #0284c7;
  --dl-color-pimary-500: #4aa4e3;
  --dl-color-pimary-700: #c3e5fa;
  --dl-color-pimary-800: #e3e8ef;
  --dl-color-pimary-900: #f2f5f9;
  --dl-space-space-unit: 1rem;
  --dl-size-size-halfunit: .5rem;
  --dl-color-secondary-100: #111729;
  --dl-color-secondary-300: #1e293b;
  --dl-color-secondary-400: #334155;
  --dl-color-secondary-500: #677b8e;
  --dl-color-secondary-700: #94a3b8;
  --dl-radius-radius-round: 50%;
  --dl-size-size-doubleunit: 2rem;
  --dl-size-size-tripleunit: 3rem;
  --dl-space-space-halfunit: .5rem;
  --dl-radius-radius-radius4: 4px;
  --dl-space-space-fiveunits: 5rem;
  --dl-radius-radius-radius25: .25rem;
  --dl-radius-radius-radius50: .5rem;
  --dl-radius-radius-radius75: .75rem;
  --dl-space-space-doubleunit: 2rem;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-tripleunit: 3rem;
  --dl-space-space-twoandhalf: 2.5rem;
  --dl-space-space-unitandhalf: 1.5rem;
  --dl-space-space-triplequarter: .75rem;
}

.teleport-show {
  display: flex !important;
  transform: none !important;
}

.list {
  width: 100%;
  margin: 1em 0;
  padding: 0 0 0 1.5rem;
  list-style-type: none;
  list-style-position: outside;
  display: block;
}

.list-item {
  display: list-item;
}

.button {
  color: var(--dl-color-gray-900);
  border-color: var(--dl-color-gray-900);
  background-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  display: inline-block;
}

.textarea {
  color: var(--dl-color-gray-900);
  cursor: auto;
  border-color: var(--dl-color-gray-900);
  background-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: 4px;
  padding: .5rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.input {
  color: var(--dl-color-gray-900);
  cursor: auto;
  border-color: var(--dl-color-gray-900);
  background-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.email {
  background-image: 7beb06af-3dc7-441c-b8e7-01bde6c4e861;
}

.filtered {
  filter: url("#f1");
  width: 100%;
}

.vignette {
  box-sizing: border-box;
  align-content: center;
  align-items: center;
  box-shadow: inset 0 -5rem 10rem #0009;
}

.header {
  padding: var(--dl-space-space-unitandhalf);
  z-index: 5;
  backdrop-filter: blur(.035rem);
  background: linear-gradient(#0000009f 0%, #000000bf 6.25% 12.5%, #010101bf 18.75%, #020202be 25%, #040404bd 31.25%, #070707bc 37.5%, #0b0b0bba 43.75%, #101010b8 50%, #171717b5 56.25%, #1f1f1fb1 62.5%, #2a2a2aac 68.75%, #363636a7 75%, #454545a0 81.25%, #56565698 87.5%, #6969698f 93.75%, #80808085 100%);
  border: .05rem solid #b1b1b160;
  border-top: none;
  border-radius: 1.25rem;
  flex-direction: row;
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1320px;
  height: 75px;
  margin-bottom: .75rem;
  padding-top: 2rem;
  display: flex;
  position: fixed;
  box-shadow: 0 .5rem 1.5rem #0000001a;
}

.Healine {
  text-transform: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  text-decoration: none;
}

.TextXL {
  text-transform: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  text-decoration: none;
}

.TextSM {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: .87rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
}

.TextXS {
  text-transform: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: .75rem;
  font-weight: 500;
  text-decoration: none;
}

.Content {
  text-transform: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1.12rem;
  font-weight: 300;
  line-height: 1.75;
  text-decoration: none;
}

.TextMD {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
}

.Text2XL {
  text-transform: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.25;
  text-decoration: none;
}

.Text3XL {
  text-transform: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 3rem;
  font-weight: 500;
  text-decoration: none;
}

.TextLG {
  text-transform: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  text-decoration: none;
}
/*# sourceMappingURL=index.16a9420e.css.map */
